<template>
  <button @click="clickedNotification(notification)" v-for="notification in notifications"
          class="flex items-center gap-1 py-1 px-1 hover:bg-theme-bg w-full">
    <UserImage
        :imageId="
          notification.senderProfileImageId
            ? notification.senderProfileImageId
            : undefined
        "
        class="flex-none"
    />
    <i18n-t
        :keypath="'notification_texts.' + notification.notificationType"
        scope="global"
        tag="p"
        class="text-sm grow text-left"
    >
      <template v-slot:sender
                v-if="notification.notificationType != 'organization_spot_archived' || notification.notificationType != 'own_spot_was_archived' || notification.notificationType != 'own_spot_will_be_archived' "
      >{{ notification.senderName }}
      </template
      >
      <template
          v-if="notification.notificationType == 'own_comment_response' || notification.notificationType == 'spot_follow_new_comment'"
          v-slot:comment
      >"{{ notification.commentText.substring(0, 15) }}&hellip;"
      </template
      >
      <template
          v-if="notification.notificationType == 'own_spot_category_added'"
          v-slot:spot
      >"{{ notification.spotTitle.substring(0, 15) }}&hellip;"
      </template
      >
      <template
          v-else-if="notification.notificationType == 'spot_follow_new_comment'"
          v-slot:spot
      >"{{ notification.spotTitle.substring(0, 15) }}&hellip;"
      </template
      >
      <template
          v-else
          v-slot:spot
      >"{{ notification.spotTitle.substring(0, 15) }}&hellip;"
      </template
      >
    </i18n-t>
    <span v-if="!notification.read" class="shrink-0 h-[12px] w-[12px] bg-theme-good rounded-[6px]"></span>
  </button>
</template>
<script setup lang="ts">

defineProps<{ notifications: Notification[] }>()
const emits = defineEmits(['closeDrawer']);

const router = useRouter();

function clickedNotification(notification: Notification) {

  if (!notification.read) {
    useApi().get(`users/current/notifications/${notification.id}/read`).then(() => {
      notification.read = true
    }).finally(() => {
      router.push(linkForNotification(notification));
      emits('closeDrawer');
    })
  } else {
    router.push(linkForNotification(notification));
    emits('closeDrawer');
  }
}

function linkForNotification(notification: Notification) {

  var url = '/spots/' + notification.spotId;

  if (notification.notificationType == 'own_comment_response' || notification.notificationType == 'spot_follow_new_comment') {
    url += '#comment-' + notification.commentId;
  } else if (notification.notificationType == 'own_spot_category_added') {
    url += '#categories';
  } else if (notification.notificationType == 'spot_follow_new_comment') {
    url += '#comment-' + notification.commentId
  }

  return url;
}

</script>
